import React, { useState, useEffect } from "react";
import Title from "../component/BlogsComponents/Title";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { cosmoshub } from "../utils/Cosmoshub";
import Footer from "./Footer";
import Cosmoshubimg from "../../Images/COSMOSHUB_img.png";
import Multiplepoints from "../component/BlogsComponents/Multiplepoints";
import Detailedcards from "../component/DetailedCards";
import { atfcoes } from "../utils/List";
import ATFCOECard from "../component/ATFCOECard";
import ATFCOEActivecard from "../component/ATFCOEActivecard";

const Cosmoshub = (props) => {
  const [arrow, setArrow] = useState(true);
  const [clickedvalue, setClickedvalue] = useState(null);
  const [flag, setFlag] = useState(false);
  const [activecard, setActivecard] = useState(null);

  const clickedObjective = (con) => {
    // if (clickedvalue != null && !arrow) {
    //   alert(clickedvalue);
    // }

    setClickedvalue(con);
    setArrow(!arrow);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const coecardflag = (activecarddata) => {
    setFlag(!flag);
    activecarddata && setActivecard(activecarddata);
  };

  return (
    <>
      <div className="cosmoshub">
        {/* <div className="cosmoshub__herotitle">
          ArcticTurn Foundation Center Of excellence
        </div> */}
        <div className="cosmoshub__herotitle">C O S M O S &nbsp; H U B</div>{" "}
        <div className="cosmoshub__herotitle_subtitle">
          Center of Sustainability, Mobility and Outreach for Society
        </div>
      </div>
      <div className="SGC__colorliner"></div>

      {flag ? (
        // <ATFCOEActivecard data={activecard} changeFlag={() => coecardflag()} />
        <div></div>
      ) : (
        <div>
          {/* <div className="ATFCOEs">
            {atfcoes.map((coe) => (
              <ATFCOECard
                data={coe}
                textalign="center"
                changeFlag={(activecarddata) => coecardflag(activecarddata)}
              />
            ))}
          </div> */}

          <div
            className="cosmoshub__content"
            style={{ borderRadius: "2rem", border: "black solid 1px" }}
          >
            <div className="cosmoshub__content__section">
              <div className="cosmoshub__content__section__textsection">
                {/* <div className="cosmoshub__content__section__textsection__title">
              COSMOS Hub
            </div> */}
                <div className="cosmoshub__content__section__textsection__text1">
                  At the COSMOS Hub within the university, we are dedicated to
                  providing a vibrant ecosystem that fosters talent mobility,
                  incubates projects, facilitates knowledge sharing, develops
                  future leaders, and generates impactful research outcomes.
                  Through these activities, we strive to make a meaningful
                  contribution to sustainability and drive positive change in
                  society.
                </div>
              </div>
              <div className="cosmoshub__content__section__imgsection">
                <img
                  className="cosmoshub__content__section__imgsection_img"
                  src={Cosmoshubimg}
                />
              </div>
            </div>
          </div>

          {/* {cosmosobjectives.map((obj) => (
        <div className="cosmoshub__section" key={obj.value}>
          <div
            className="cosmoshub__section__card"
            onClick={(con) => clickedObjective(obj.value)}
          >
            <div className="cosmoshub__section__card_arrow">
              {clickedvalue === obj.value && !arrow ? (
                <AiFillCaretDown />
              ) : (
                <AiFillCaretRight />
              )}
            </div>
            <div className="cosmoshub__section__card_title">{obj.title}</div>
          </div>

          {clickedvalue === obj.value && !arrow ? (
            <div className="cosmoshub__section__content">{obj.text}</div>
          ) : (
            ""
          )}
        </div>
      ))} */}

          <Multiplepoints
            text1="What exactly"
            text2="COSMOS Hub"
            text3="Provides ?"
            border="false"
            data={cosmoshub}
            align="center"
          />
        </div>
      )}

      <Footer />
    </>
  );
};

export default Cosmoshub;
