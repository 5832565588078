export const poplist = [
  {
    id: "ATFSD0001",
    status: "open",
    value: 1,
    //img: "https://images.unsplash.com/photo-1573227896778-8f378c4029d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGVhdGhlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    //img: "https://media.licdn.com/dms/image/C5603AQF34U_Jg5p3JQ/profile-displayphoto-shrink_800_800/0/1516312399693?e=1692230400&v=beta&t=l3nZTq33FZVsIn7PMgnVPYqQwW57UJuQNF6eQjRSyw0",
    //img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle.png?alt=media&token=7cafb32c-e500-4347-b61e-0453fc05871c&_gl=1*36eqb4*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5Njk0Mi4wLjAuMA..",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle_leather.png?alt=media&token=e1f9ba34-57a0-4346-8487-080bea975e1c&_gl=1*qramt5*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5NzA5My4wLjAuMA..",
    title: "Mr. Sateesh Damle",
    industry: "CEO, SROTT ",
    link: "Sateesh-Damle",
    domain: "Leather Industry",
    currentprojects: "Sustainable Leather",
    workexperience: "43 years",
    location: "Pune, Maharashtra, India",
    about:
      "ArcticTurn Foundation proudly presents Prof. Sateesh Damle, a distinguished expert in the leather industry with over 43 years of experience. Prof. Damle's deep expertise lies in manufacturing, sourcing, and quality control, making him a trusted authority in the field. He is renowned for his innovative and sustainable leather solutions, including metal-free and bio-degradable options. With a strong commitment to driving positive change, Prof. Damle has collaborated with global brands and streamlined processes for leather goods makers, consistently delivering exceptional results. His profound knowledge, craftsmanship, and reliability make him an invaluable asset to the world of leather.",
    education1: "B.Sc. in leather Technology, University of Northampton UK",
    education2: "Leather making course from London U.K",
  },

  {
    id: "ATFAS0003",
    status: "close",
    value: 3,
    //img: "https://images.unsplash.com/photo-1573227896778-8f378c4029d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGVhdGhlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    //img: "https://media.licdn.com/dms/image/C5603AQF34U_Jg5p3JQ/profile-displayphoto-shrink_800_800/0/1516312399693?e=1692230400&v=beta&t=l3nZTq33FZVsIn7PMgnVPYqQwW57UJuQNF6eQjRSyw0",
    //img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle.png?alt=media&token=7cafb32c-e500-4347-b61e-0453fc05871c&_gl=1*36eqb4*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5Njk0Mi4wLjAuMA..",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/Amit%20salvi.png?alt=media&token=b1267d28-cc11-47a1-8d67-dd675e5632b5",
    title: "Mr. Amit Salvi",
    industry: "Senior Scientist at TCS Research",
    link: "Amit-Salvi",
    domain: "Public Health",
    currentprojects:
      "Early Detection of Malnutrition at India’s Nutritional Hubs",
    workexperience: "12 years",
    location: "Pune, Maharashtra, India",
    about:
      "Prof. Dr. Nachiket Thakur, an esteemed strategist and executioner with 21 years of design and innovation-led transformational experience in various industries. Currently serving as the Director of MIT Institute of Design at MIT Art, Design & Technology University in Pune, Prof. Thakur has made significant contributions to the field of design. Throughout his career, Prof. Thakur has held key leadership positions, including Director of Innovation and Entrepreneurship Development at the Science & Technology Park (STP) in Pune. In this role, he mentored innovators and new technology startups, fostering a culture of innovation and growth. Additionally, he served as the Head of Research and Development team at Araymond India, a 150-year-old French multinational auto component company. Prof. Thakur also held positions as Head of the Innovation Center and GM Product Design & Development at Mahindra Composites, Principle Designer at Forbes Marshall, and Product Designer at Design Directions.",
    education1: "Bachelor's Degree in Mechanical Engineering, Pune University",
    education2: "Master's Design, IDC School of Design at IIT Mumbai",
  },

  {
    id: "ATFPA0005",
    status: "open",
    value: 5,
    //img: "https://images.unsplash.com/photo-1573227896778-8f378c4029d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGVhdGhlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    //img: "https://media.licdn.com/dms/image/C5603AQF34U_Jg5p3JQ/profile-displayphoto-shrink_800_800/0/1516312399693?e=1692230400&v=beta&t=l3nZTq33FZVsIn7PMgnVPYqQwW57UJuQNF6eQjRSyw0",
    //img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle.png?alt=media&token=7cafb32c-e500-4347-b61e-0453fc05871c&_gl=1*36eqb4*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5Njk0Mi4wLjAuMA..",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/Parag%20apte%20edited.png?alt=media&token=d98af57a-fe7d-4660-b21c-fe66f558895b",
    title: "Mr. Parag Apte",
    industry: "Director, ACE Coating India Pvt. Ltd",
    link: "parag-apte",
    domain: "Public Health",
    currentprojects:
      "Early Detection of Malnutrition at India’s Nutritional Hubs",
    workexperience: "20 years",
    location: "Pune, Maharashtra, India",
    about:
      "Mr. Parag Anil Apte is a seasoned professional in mechanical engineering and business development. Notably, he has played a pivotal role in planning, designing, and manufacturing mechanical systems. Mr. Apte has also demonstrated remarkable expertise in building extensive networks for product distribution. His achievements include successfully overseeing presales and after-sales activities, securing substantial funding for projects, and collaborating with industry professionals and technocrats to deliver cutting-edge solutions. As a skilled CAD engineer and a proficient user of AutoCAD, his contributions have extended to designing trailers and pipelines. Mr. Apte's commitment to excellence and innovative thinking has been instrumental in his successful career.",
    education1: "Bachelor's Degree in Mechanical Engineering, Pune University",
    education2: "Master's Design, IDC School of Design at IIT Mumbai",
  },
  {
    id: "ATFNT0002",
    status: "open",
    value: 2,
    //img: "https://images.unsplash.com/photo-1573227896778-8f378c4029d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGVhdGhlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    //img: "https://media.licdn.com/dms/image/C5603AQF34U_Jg5p3JQ/profile-displayphoto-shrink_800_800/0/1516312399693?e=1692230400&v=beta&t=l3nZTq33FZVsIn7PMgnVPYqQwW57UJuQNF6eQjRSyw0",
    //img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle.png?alt=media&token=7cafb32c-e500-4347-b61e-0453fc05871c&_gl=1*36eqb4*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5Njk0Mi4wLjAuMA..",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/nachiket_thakur.png?alt=media&token=5c8d6fbb-e8a7-4657-a652-e54a522feb84&_gl=1*12e8gqd*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjY0NDE0My44LjEuMTY4NjY0NDUxOS4wLjAuMA..",
    title: "Prof. Dr. Nachiket Thakur",
    industry: "Director, MIT Institute of Design",
    link: "Nachiket-Thakur",
    domain: "Sustainable Infrastructure",
    currentprojects: "Centre of Excellence - Leather Lab",
    workexperience: "21 years",
    location: "Pune, Maharashtra, India",
    about:
      "Prof. Dr. Nachiket Thakur, an esteemed strategist and executioner with 21 years of design and innovation-led transformational experience in various industries. Currently serving as the Director of MIT Institute of Design at MIT Art, Design & Technology University in Pune, Prof. Thakur has made significant contributions to the field of design. Throughout his career, Prof. Thakur has held key leadership positions, including Director of Innovation and Entrepreneurship Development at the Science & Technology Park (STP) in Pune. In this role, he mentored innovators and new technology startups, fostering a culture of innovation and growth. Additionally, he served as the Head of Research and Development team at Araymond India, a 150-year-old French multinational auto component company. Prof. Thakur also held positions as Head of the Innovation Center and GM Product Design & Development at Mahindra Composites, Principle Designer at Forbes Marshall, and Product Designer at Design Directions.",
    education1: "Bachelor's Degree in Mechanical Engineering, Pune University",
    education2: "Master's Design, IDC School of Design at IIT Mumbai",
  },
  {
    id: "ATFMK0004",
    status: "open",
    value: 4,
    //img: "https://images.unsplash.com/photo-1573227896778-8f378c4029d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGVhdGhlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80",
    //img: "https://media.licdn.com/dms/image/C5603AQF34U_Jg5p3JQ/profile-displayphoto-shrink_800_800/0/1516312399693?e=1692230400&v=beta&t=l3nZTq33FZVsIn7PMgnVPYqQwW57UJuQNF6eQjRSyw0",
    //img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/sateesh_damle.png?alt=media&token=7cafb32c-e500-4347-b61e-0453fc05871c&_gl=1*36eqb4*_ga*NTUwMTA5NTUyLjE2ODM2MzI5MTk.*_ga_CW55HF8NVT*MTY4NjQ5Njg4Mi43LjEuMTY4NjQ5Njk0Mi4wLjAuMA..",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/mandarkpop.png?alt=media&token=f51526ab-7a54-43fe-87a0-88bb5e667ff0",
    title: "Prof. Mandar Karyakarte",
    industry: "Professor & Researcher at VIIT, Pune",
    link: "Mandar-Karyakarte",
    domain: "Public Health",
    currentprojects:
      "Early Detection of Malnutrition at India’s Nutritional Hubs",
    workexperience: "20 years",
    location: "Pune, Maharashtra, India",
    about:
      "Prof Mandar Karyakarte is an educationist in field of Computer Engineering- with 20+ years of experience. He is currently serving as Professor in Department of Computer Engineering at Vishwakarma Institute of Information Technology, Pune India. As educationist Prof Mandar Karyakarte is involved in shaping students’ skill development though projects in the domain of Industry 4.0. He has received funding of INR 45+ Lacs for various projects.  He has collaborations with industry professionals, academicians, research fellows and technocrats to deliver digital solutions. He is reviewer for prestigious journals like Springer Wireless Personal Communications, Elsevier Computer Network Journal. He also shoulders the responsibility of IT resource acquisition at his institute.",
    education1: "PhD in Computer Engineering",
  },
];

//About Damle : "ArcticTurn proudly presents Prof. Sateesh Damle, a distinguished expert in the leather industry with over 43 years of experience. Prof. Damle's deep expertise lies in manufacturing, sourcing, and quality control, making him a trusted authority in the field. He is renowned for his innovative and sustainable leather solutions, including metal-free and bio-degradable options. With a strong commitment to driving positive change, Prof. Damle has collaborated with global brands and streamlined processes for leather goods makers, consistently delivering exceptional results. His profound knowledge, craftsmanship, and reliability make him an invaluable asset to the world of leather."
