import React from "react";

const LiveIcon = () => {
  return (
    <div className="live-icon">
      <span className="live-text">LIVE</span>
      <span className="live-dot"></span>
    </div>
  );
};

export default LiveIcon;
