import React, { useEffect } from "react";
import { researchprojects } from "../utils/ProjectList";
import Researchprojectimg from "../../Images/researchproject.png";

import Footer from "./Footer";
import Blogcard from "../component/Blogcard";
import { Link } from "react-router-dom";

const Researchproject = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <div className="blogs__header">Research Projects</div> */}

      <div className="cosmoshub">
        <div className="cosmoshub__content">
          <div className="cosmoshub__content__section">
            <div className="cosmoshub__content__section__textsection">
              <div className="cosmoshub__content__section__textsection__title">
                Research Projects
              </div>
              <div className="cosmoshub__content__section__textsection__text">
                The ArcticTurn Foundation is actively involved in conducting
                research projects focused on sustainability. With a strong
                commitment to addressing global challenges, ATF undertakes
                research in areas such as renewable energy, sustainable
                agriculture, circular economy, climate change, and urban
                development. By collaborating with academic institutions,
                industry partners, and individual researchers.
              </div>
            </div>
            <div className="cosmoshub__content__section__imgsection">
              <img
                className="cosmoshub__content__section__imgsection_img"
                src={Researchprojectimg}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>

      <div className="blogs__blogsectiontitle">Projects</div>
      <div className="blogs__blog">
        {researchprojects.map((project) => (
          <div className="blogs__blogsarea">
            {project.status === "open" ? (
              <Link to={project.title}>
                <Blogcard
                  img={project.img}
                  title={project.title}
                  subtext={project.subtext}
                  key={project.value}
                  domain={project.domain}
                  collaboration={project.collaboration}
                />
              </Link>
            ) : (
              <Blogcard
                img={project.img}
                title={project.title}
                subtext={project.subtext}
                key={project.value}
                domain={project.domain}
                collaboration={project.collaboration}
              />
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Researchproject;
