import React, { useEffect } from "react";
import { poplist } from "../utils/poplist";
import Title from "../component/BlogsComponents/Title";
import Header3 from "../component/BlogsComponents/Header3";
import Header2 from "../component/BlogsComponents/Header2";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import { researchprojects } from "../utils/ProjectList";
import Blogcard from "../component/Blogcard";
import { Link } from "react-router-dom";

const Popprofile = (props) => {
  let header = null;
  let flag = true;
  const projList = [];

  poplist.forEach((profile) => {
    if (profile.link === props.link && flag) {
      header = profile;
      flag = false;
    }
  });

  const fullprofile = header;

  researchprojects.forEach((proj) => {
    proj.status === "open" &&
      proj.PIteam.forEach((member) => {
        if (member.id === fullprofile.id) {
          projList.push(proj);
        }
      });
  });

  //console.log(projList);

  //console.log(projList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="popprofile">
      {/* <Title text="Professor of Practice (PoP)" align="left" /> */}
      <div className="popprofile__profilecard">
        <div className="popprofile__profilecard__profileheader">
          <div className="popprofile__profilecard__profileheader__profilephoto">
            <img
              className="popprofile__profilecard__profileheader__profilephoto_photo"
              src={fullprofile.img}
            />
          </div>

          <div className="popprofile__profilecard__profilename">
            <Title text={fullprofile.title} align="left" />
            {/* <div className="popprofile__profilecard__profilename_tag">
              ATF Professor of Practice
            </div> */}
            <div className="detailedcards__goal__label" style={{}}>
              ATF Professor of Practice
            </div>
            <Header3 text={fullprofile.domain} header="Domain" align="left" />
            <Header3
              text={fullprofile.workexperience}
              header="Work Experience"
              align="left"
            />
            <Header3
              text={fullprofile.education1}
              header="Education"
              align="left"
            />
            {/* <Header3 text={fullprofile.education2} align="left" /> */}
          </div>
        </div>

        <div className="popprofile__profilecard__aboutsection">
          <Header2 text="About" />
          <Blogparagraph text={fullprofile.about} />

          {/* <Header2 text="Domain" />
          <Blogparagraph text={fullprofile.domain} />

          <Header2 text="Work Experience" />
          <Blogparagraph text={fullprofile.workexperience} />

          <Header2 text="Education" />
          <Blogparagraph text={fullprofile.education1} />
          <Blogparagraph text={fullprofile.education2} /> */}

          <Header2 text="Current Project(s)" />
          {/* <Blogparagraph text={fullprofile.currentprojects} /> */}
          <div className="popprofile__profilecard__project">
            {projList.map((project) => (
              <Link
                className="blogs__blogsarea"
                //to={project.title}
                to={`../research-project/${project.title}`}
                key={project.value}
              >
                <Blogcard
                  img={project.img}
                  title={project.title}
                  subtext={project.subtext}
                  key={project.value}
                  domain={project.domain}
                  collaboration={project.collaboration}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popprofile;
