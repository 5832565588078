// export const whatwedo = [
//   {
//     title: "Objectives",
//     subtext: "The primary objectives of this research project are as follows",
//     list: [
//       "ATF works hand in hand with cross-border/intra-industries, academic institutions, and individual researchers in the thematic sector of sustainable development. ATF creates a global corridor for industry, academics, and research, fostering collaboration and knowledge sharing.",
//       "ATF aligns its scope with the UN's 17th Sustainable Development Goal, emphasizing the importance of thinking globally and acting locally. ATF ensures that its framework keeps everyone in mind, leaving no one behind in the pursuit of sustainable development.",
//       "ATF commences joint research and industrial projects across borders, focusing on student and faculty mobility. This established corridor for talent mobility leads to various academic and research outcomes, facilitating knowledge exchange and collaboration.",
//       "One of ATF's objectives is to establish two startups every year with a thematic focus on sustainability. These startups are the outcome of ATF's collaborative efforts and contribute to the advancement of sustainable solutions in line with point number one.",
//     ],
//     paragraph: null,
//   },
// ];

export const whatwedo = [
  {
    value: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/collab.png?alt=media&token=02fae0ae-88af-44d8-871b-b897151ae9f7",
    title: "Facilitating Collaborations",
    text: "ATF COSMOS plays a crucial role in fostering collaborations between academics, enterprises, and researchers in the field of sustainability. By bringing together individuals and institutions with a shared passion for creating a more sustainable future, COSMOS serves as a hub for knowledge exchange and cooperative efforts.",
  },
  {
    value: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/knowledge.png?alt=media&token=27b5ea9b-7f4b-4c8d-ae17-31ba6ad17e2a",
    title: "Knowledge Exchange",
    text: "ATF COSMOS recognizes the importance of knowledge exchange in advancing sustainability efforts. By providing a platform for academics, enterprises, and researchers to share their insights, best practices, and research findings, COSMOS facilitates the dissemination of valuable information within the sustainability community.",
  },
  {
    value: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/pathways.png?alt=media&token=c3e32a9b-79d6-4c6f-b2fb-bf4a761f760e",
    title: "Create Green Pathways",
    text: "As a central hub, COSMOS actively works towards creating green pathways that connect individuals and institutions committed to sustainability. These pathways serve as channels for collaboration, innovation, and action, enabling stakeholders to work together towards a common goal.",
  },
];

export const whytm = [
  {
    value: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/2.png?alt=media&token=980b4cff-ce92-41b1-be1a-184228ad36d0",
    title: "Academic Grants",
    text: "Get support for your studies with Academic Grants: a boost for your educational journey.",
  },
  {
    value: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/3.png?alt=media&token=86425b69-4755-44bc-bf18-dd5a9960f848",
    title: "Hands-on Project Experience",
    text: "Dive into practical learning with Hands-on Project Experience: where theory meets the real world.",
  },
  {
    value: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/4.png?alt=media&token=87e6301f-0fcc-4df1-bb36-523a9d1b6850",
    title: "Global Perspective",
    text: "See the world through a Global Perspective: a chance to learn beyond borders.",
  },
  {
    value: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/5.png?alt=media&token=cf8ddb1a-55d0-4748-b0f6-f59ad8071912",
    title: "Diverse Academic Engagement",
    text: "Enjoy a variety of subjects with Diverse Academic Engagement: a rich and well-rounded education.",
  },
  {
    value: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/6.png?alt=media&token=6fd53228-b20b-414a-b221-98e7a872a480",
    title: "Focused Industry Networking",
    text: "Build your career network with Focused Industry Networking: connecting you with future opportunities.",
  },
  {
    value: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/7.png?alt=media&token=621503f7-fb73-4e46-b6a8-5471621bd957",
    title: "Project management and leadership skills",
    text: "Develop Project Management and Leadership Skills: Elevate your abilities for success in any professional endeavor.",
  },
];

export const privacypolicylist = [
  {
    value: 1,
    title: "Information Collection and Use",
    para1:
      "While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address, phone number, and other information ('Personal Information')",
    para2:
      "We use your Personal Information only for the purpose of providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.",
  },
  {
    value: 2,
    title: "Log Data",
    para1:
      "Like many site operators, we collect information that your browser sends whenever you visit our Site ('Log Data'). This Log Data may include information such as your computer's Internet Protocol ('IP') address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.",
  },
  {
    value: 3,
    title: "Cookies",
    para1:
      "Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive.",
    para2:
      "We use 'cookies' to collect information and improve the user experience. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.",
  },
  {
    value: 4,
    title: "Security",
    para1:
      "The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.",
  },
  {
    value: 5,
    title: "Third-Party Links",
    para1:
      "Our Site may contain links to third-party sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.",
    para2:
      "ArcticTurn Foundation has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
  },
  {
    value: 6,
    title: "Changes to This Privacy Policy",
    para1:
      "This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.",
    para2:
      "We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.",
  },
  {
    value: 7,
    title: "Contact Us",
    para1:
      "If you have any questions about this Privacy Policy, please contact us at info@arcticturn.in.",
  },
];

export const termsofuselist = [
  {
    value: 1,
    title: "Use of the Site",
    para1:
      "The content and materials on this Site are provided for general information purposes only. Your use of any information or materials on this Site is entirely at your own risk, for which we shall not be liable. It shall be your responsibility to ensure that any products, services, or information available through this Site meet your specific requirements.",
    para2: null,
  },
  {
    value: 2,
    title: "Intellectual Property",
    para1:
      "The Site and its original content, features, and functionality are owned by ArcticTurn Foundation and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
    para2:
      "You may not reproduce, distribute, modify, transmit, publicly display, publicly perform, reproduce, publish, license, create derivative works from, transfer, or sell any content or information obtained from the Site without our prior written consent.",
  },
  {
    value: 3,
    title: "User Contributions",
    para1:
      "By submitting content or materials to the Site, you grant ArcticTurn Foundation a perpetual, irrevocable, worldwide, royalty-free, non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such content or materials, in whole or in part, and/or to incorporate it into other works in any form, media, or technology now known or later developed, for any purpose, without compensation to you.",
    para2:
      "You agree that any content or materials you submit will not violate any right of any third party, including copyright, trademark, privacy, personality, or other personal or proprietary right. You further agree that your content will not contain libelous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Site or any related website.",
  },
  {
    value: 4,
    title: "Limitations of Liability",
    para1:
      "The content on this Site is provided on an 'as-is' basis. ArcticTurn Foundation makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.",
    para2:
      "ArcticTurn Foundation will not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use of or inability to use the Site, even if advised of the possibility of such damages.",
  },
  {
    value: 5,
    title: "Links to Other Websites",
    para1:
      "Our Site may contain links to third-party websites or services that are not owned or controlled by ArcticTurn Foundation. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.",
    para2: null,
  },
  {
    value: 6,
    title: "Changes to the Terms of Use",
    para1:
      "We reserve the right to update or modify these Terms of Use at any time without prior notice. Any changes are effective immediately upon posting to the Site. Your continued use of the Site after the posting of the revised Terms of Use constitutes your acceptance of the changes.",
    para2: null,
  },
  {
    value: 7,
    title: "Governing Law",
    para1:
      "These Terms of Use shall be governed and construed in accordance with the laws of Maharashtra/India, without regard to its conflict of law provisions.",
    para2: null,
  },
  {
    value: 8,
    title: "Contact Us",
    para1:
      "If you have any questions about these Terms of Use, please contact us at info@arcticturn.in.",
    para2: null,
  },
];

// ("Facilitating Collaborations: COSMOS plays a crucial role in fostering collaborations between academics, enterprises, and researchers in the field of sustainability. By bringing together individuals and institutions with a shared passion for creating a more sustainable future, COSMOS serves as a hub for knowledge exchange and cooperative efforts.");
// ("Knowledge Exchange: COSMOS recognizes the importance of knowledge exchange in advancing sustainability efforts. By providing a platform for academics, enterprises, and researchers to share their insights, best practices, and research findings, COSMOS facilitates the dissemination of valuable information within the sustainability community.");
// ("Creating Green Pathways: As a central hub, COSMOS actively works towards creating green pathways that connect individuals and institutions committed to sustainability. These pathways serve as channels for collaboration, innovation, and action, enabling stakeholders to work together towards a common goal.");

export const unlvprojectlist = [
  {
    value: 1,
    title: "Internet of Things ",
    para1: null,
  },
  {
    value: 2,
    title: "Robotics",
    para1: null,
  },
  {
    value: 3,
    title: "Autonomous Vehicles",
    para1: null,
  },
  {
    value: 4,
    title: "Scalable machine learning for big data",
    para1: null,
  },
  {
    value: 5,
    title: "Semantic Image Segmentation",
    para1: null,
  },
  {
    value: 6,
    title: "Pathological Image Analysis",
    para1: null,
  },
  {
    value: 7,
    title: "Survival Analysis Using Genomic Data",
    para1: null,
  },
];

export const week8features = [
  {
    value: 1,
    title: "Networking session",
    text: "Collaboration of various industries and academic stakeholders",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/week8-1.png?alt=media&token=23134e5a-03e9-4f4e-ba25-e87452fb1005",
  },
  {
    value: 2,
    title: "Industrial Trek",
    text: "Visits to Industries and innovation centers IN USA",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/week8-2.png?alt=media&token=43469cec-264f-41c9-81ca-70bb16a26dd6",
  },
  // {
  //   value: 3,
  //   title: "Case Study Presentation",
  //   text: "Ideathon presentations by students to map Indian SME’s",
  //   img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/week8-3.png?alt=media&token=f1859cfb-5ce2-4497-bff7-8ba78f605650",
  // },
  {
    value: 4,
    title: "Indian SME Allocation",
    text: "Real-world business challenges through strategic case studies, fostering their business acumen.",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/week8-4.png?alt=media&token=2a98042e-d08a-4e18-b82d-461908ae89dc",
  },
  {
    value: 5,
    title: "Closing Ceremony",
    text: "Recognize and honor the accomplishment of fellowship studnets’s",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/week8-5.png?alt=media&token=295f0b5f-9c82-4418-b79e-ec041544968e",
  },
];
