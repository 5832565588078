import React from "react";

const Highlightcard = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };
  return (
    <>
      <div className="highlightcard">
        <div className="highlightcard__img">
          {props.img ? (
            <img src={props.img} className="highlightcard__img__cover" />
          ) : null}
        </div>
        <div className="highlightcard__info">
          <div className="highlightcard__info__blogheading">{props.title}</div>
          <div className="highlightcard__info__blogcredits">
            {props.subtext}
          </div>
        </div>
      </div>
    </>
  );
};

export default Highlightcard;
