import React, { useState } from "react";

const ImageCarousel = ({ imageLinks }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageLinks.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageLinks.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="image-carousel">
      <button className="nav-button prev" onClick={prevImage}>
        &#8249; {/* Left-pointing angle bracket */}
      </button>
      <div className="image-container">
        <img src={imageLinks[currentIndex]} alt={`Image ${currentIndex + 1}`} />
      </div>
      <button className="nav-button next" onClick={nextImage}>
        &#8250; {/* Right-pointing angle bracket */}
      </button>
      <div className="image-counter">
        {currentIndex + 1} / {imageLinks.length}
      </div>
    </div>
  );
};

export default ImageCarousel;
