import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { db } from "../../Firebase/config";

const Subscribe = () => {
  const [email, setEmail] = useState({ email: "" });
  const [feedback, setFeedback] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (prop) => (event) => {
    setEmail({ ...email, [prop]: event.target.value });
  };

  const handleSubmit = async () => {
    var res = await db
      .collection("Subscribers")
      .add(email)
      .then(() => {
        setFeedback(true);
        setEmail({ email: "" });
      });
  };
  return (
    <div className="blogs">
      <div className="blogs__header">Subscribe to ATF Newsletter </div>
      <div className="subscribe_container">
        <div className="contactsection__contactform-form">
          <div className="contactsection__contactform-form-allinput">
            <div className="contactsection__contactform-form-allinput-input">
              Your Email Id:
              <input
                required
                onChange={handleChange("email")}
                value={email.email}
                type="email"
                name="email"
                className="contactsection__contactform-form-allinput-input-inputfield"
              />
            </div>
            <div>
              <button
                className="contactsection__contactform-form-button"
                onClick={handleSubmit}
              >
                {feedback ? "Thank you" : "Subscribe"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subscribe;
