import React from "react";
import Title from "../component/BlogsComponents/Title";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import Contactcard from "../component/Contactcard";
import Centerofexcellencecard from "../component/Centerofexcellencecard";
import VIIT from "../../Images/VIT-logo.png";
import Footer from "./Footer";

const Centerofexcellence = (props) => {
  return (
    <>
      <div className="centerofexcellence">
        <Title text="Center of Excellence " />

        <Blogparagraph
          align="center"
          text="Welcome to the Arcticturn Foundation on Center of Excellence (ATF COE)! We are dedicated to fostering innovation, collaboration, and sustainable development in the thematic sector of sustainability. Through joint research projects, student-faculty mobility, and the establishment of startups, we create a global corridor that connects industries, academic institutions, and individual researchers. Join us as we work towards a more sustainable future, making a positive impact on society and the environment."
        />

        {/* <Title text="Arcticturn's Center of excellence" /> */}
        <div className="centerofexcellence__ATFCE">
          Arcticturn's Center of excellence
        </div>
        <Centerofexcellencecard
          // img={VIIT}
          title="Vishwakarma Institute of Information Technology CoE"
          //subtext="6 June Tuesday 19:00:00 IST"
          //align="left"
        />
      </div>
      <Footer />
    </>
  );
};

export default Centerofexcellence;

// pranav chaware: Here is what VIIT x ATF COE focuses on:
// 1. VIAT-COE works hand in hand with intra-cross industries, academic institutions and individual researchers in a thematic sector of sustainability by creating a global corridor for industry, academics and research.

// 2. Aligning VIAT-COE's scope with the UN's 17th goal, VIAT-COE is keeping the framework of thinking globally act locally ensuring no one is left behind.

// 3. By commencing a joint research/industrial project (intra-cross), VIAT-COE is also focusing on student-faculty mobility through an established corridor under the talent mobility theme ensuring various academic and research outcomes.

// 4. VIAT-COE's one of the objectives to establish 2 startups every year underlying the same thematic view of sustainability which will be the outcome of point no. 1
