import React, { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import Logo from "../../Images/Logo.png";
import SmallLogo from "../../Images/smallLogo.png";
import { HiMenu, HiMenuAlt3 } from "react-icons/hi";
import Drawer from "../component/Drawer";

import { Link } from "react-router-dom";

const Navbar = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };

  const [menubuttonClick, setMenubuttonclick] = useState(false);
  const [linkClick, setLinkclick] = useState(null);

  const clickLink = () => {
    setMenubuttonclick(false);
  };
  return (
    <div className="topsection">
      {/* <div className="topsectionvoid"></div> */}
      <div className="navbar">
        <div className="navbar__logo">
          <img src={Logo} className="navbar__logo" />
        </div>
        <div className="navbar__smallLogo">
          <img src={Logo} className="navbar__logo__img" />
        </div>
        {/* below commented code is previously used to make ARCTICTURN FOUNDATION text appeared like a logo */}
        {/* <div className="navbar__Mainheader">
          <Link
            className="navbar__Mainheader__link"
            to="/"
            onClick={() => setLinkclick(null)}
          >
            ArcticTurn Foundation
          </Link>
        </div> */}
        {/* now putting menu link here code below */}
        <div className="menubar">
          {props.menulist.map((element) => (
            <div className="menubar__SecondaryButton" key={element.value}>
              <Link
                className="menubar__SecondaryButton__link"
                to={element.link}
                key={element.value}
                onClick={() => setLinkclick(element.value)}
                onLoad={() => setLinkclick(element.value)}
                style={
                  element.value === linkClick || element.value === 7
                    ? {
                        fontWeight: 700,
                        fontSize: "2rem",
                        color: "rgb(1, 51, 51)",
                      }
                    : null
                }
              >
                {element.title}
              </Link>
            </div>
          ))}
        </div>
        {/* <div className="navbar__SecondaryButton"></div> */}
        <div
          className="navbar__MenuIcon"
          onClick={() => {
            setMenubuttonclick(!menubuttonClick);
          }}
        >
          {menubuttonClick ? <HiMenuAlt3 /> : <HiMenu />}
        </div>
      </div>
      {/* <div className="menubar"> menu link was here previosuly  </div> */}
      {menubuttonClick ? <Drawer isClicked={() => clickLink()} /> : ""}
    </div>
  );
};

export default Navbar;
