import React from "react";
import Blogcard from "../component/Blogcard";
import Contactcard from "../component/Contactcard";
import Lether from "../../Images/lether.jpg";
import Highlightcard from "../component/Highlightcard";
import { researchprojects } from "../utils/ProjectList";

const Noticeboard = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };
  return (
    <div className="noticeboard">
      <div className="noticeboard__projecthighlights">
        <div className="noticeboard__projecthighlights_header">
          Project Highlights
        </div>
        {researchprojects !== null && (
          <>
            <Highlightcard
              img={researchprojects[0].img}
              title={researchprojects[0].title}
              subtext={researchprojects[0].subtext}
            />
            <Highlightcard
              img={researchprojects[5].img}
              title={researchprojects[5].title}
              subtext={researchprojects[5].subtext}
            />
          </>
        )}
      </div>
      <div className="noticeboard__projecthighlights">
        <div className="noticeboard__projecthighlights_header">Events</div>
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/TATA%20visit%20MIT.JPG?alt=media&token=7b7b6eb6-b602-4439-91ae-7449358cc1d6"
          title="Tata International's visit to ATF-MIT Leather Center of Excellence (CoE) at MIT-ID Pune."
          subtext="Mon 7 Nov 2023 10:30:00 IST"
        />
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/VIIT%20Pune%20%26%20ATF%20MoU%20signing%20.jpeg?alt=media&token=63458261-db85-47df-b204-33b392b96b82&_gl=1*uualh3*_ga*NjE2NDg3NzMxLjE2OTA3Njc4Mjc.*_ga_CW55HF8NVT*MTY5NzQzNTMxNC4yMy4xLjE2OTc0MzUzNjcuNy4wLjA."
          title="ArcticTurn Foundation signed a MoU with VIIT Pune establish a CoE."
          subtext="Mon 10 Oct 2023 11:00:00 IST"
        />
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/405A9880%20(1).jpg?alt=media&token=7341bd39-ebc7-46ab-aa0c-48f534fde583"
          title="ArcticTurn Foundation signed a MoU with MIT Institute of Design to establish a CoE."
          subtext="Mon 15 Aug 2023 9:00:00 IST"
        />
        {/* <Contactcard
          title="Meeting - Thought Leadership and Expert Panels"
          subtext="Mon 12 Aug 2023 09:00:00 IST"
        /> */}

        {/* <Contactcard
          title="Visit at MIT Pune - Setup of COSMOS Lab at MIT Pune Campus"
          subtext="24 July 2023 Monday 09:00:00 IST"
        /> */}

        {/* <Contactcard
          title="Educational Workshops with Students "
          subtext="9 July 2023 Sunday 19:00:00 IST"
        /> */}
        {/* <Contactcard
          title="Steering meeting"
          subtext="6 July 2023 Thursday 12:30:00 IST"
        /> */}
      </div>
      <div className="noticeboard__projecthighlights">
        <div className="noticeboard__projecthighlights_header">
          Notification
        </div>
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/Group%20photo.jpeg?alt=media&token=875d6bfb-b009-4742-b6f9-9d14266a089c"
          title="Students at University of Nevada-Las Vegas - Talent Mobility Program 2024"
          subtext="July 2024"
        />
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/9.jpg?alt=media&token=d6b51ebc-5f89-4aab-8514-b48ed9659efd"
          title="Indian SME Allocation (ISA) 2024"
          subtext="June 2024"
        />
        <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/pop%20collaboration.png?alt=media&token=017c3b7a-d864-4029-be00-43a25a8f1496"
          title="Onboarding Professor of Prcatice(s)"
          subtext="Aug 2023 - Nov 2023"
        />

        {/* Following is apply now card for mobility */}
        {/* <a
          href="https://arcticturn.in/mobility"
          target="_blank"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Contactcard
            img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/talent%20mobility%20program%20web.png?alt=media&token=1cbf16eb-7abb-45f7-8767-8e8f4b9c8abf&_gl=1*1vrj5mb*_ga*NjE2NDg3NzMxLjE2OTA3Njc4Mjc.*_ga_CW55HF8NVT*MTY5NzQ1MDEyOC4yNS4xLjE2OTc0NTEzNDYuMzkuMC4w"
            title="Status : Open"
            subtext=""
            link="https://docs.google.com/forms/d/e/1FAIpQLSelkV-TALH47oDLh2T0oLonW1IJZk7AodEdtzH5oLu6P0w9ww/viewform?usp=sf_link"
          />
        </a> */}

        {/* <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/webinar.jpg?alt=media&token=7a4629b4-0581-4a93-8902-cbdde4340594"
          title="Webinar - Industry Trends and Insights"
          subtext="Mon 20 Oct 2023 11:00:00 IST"
        /> */}
        {/* <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/MITD_Visit.jpeg?alt=media&token=4e9e9c5d-2db0-43ac-83d6-e486b1690cb0"
          title="ATF Cosmos Hub in MITID, Loni, Pune"
          subtext="Mon 31 July 2023 11:00:00 IST"
        /> */}
        {/* <Contactcard
          img="https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/BKL%20Walwalksr.jpeg?alt=media&token=3aab31e4-236f-4c9b-a6e6-541bca9f9154"
          title="Drafting meeting with BKL Walawalkar’s Hospital,Diagnostic and Research Center."
          subtext="Mon 13 Aug 2023 9:00:00 IST"
        /> */}
      </div>
    </div>
  );
};

export default Noticeboard;
