import React, { useState } from "react";
import Aboutus from "./homepage/Aboutus";
import Founders from "./Founders";
import Footer from "./homepage/Footer";
import Homepage from "./homepage/Homepage";

import Navbar from "./homepage/Navbar";
import Hero from "./homepage/Hero";
import University from "./homepage/University";
import Coreofferings from "./homepage/Coreofferings";
import Blogsection from "./homepage/Blogsection";
import Contactsection from "./homepage/Contactsection";
import Noticeboard from "./homepage/Noticeboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Researchproject from "./homepage/Researchproject";
import Blogs from "./homepage/Blogs";
import Subscribe from "./homepage/Subscribe";
import { blogs } from "./utils/BlogsList";
import Blogpage from "./homepage/Blogpage";
import Centerofexcellence from "./homepage/Centerofexcellence";
import Pop from "./homepage/Pop";
import { poplist } from "./utils/poplist";
import Popprofile from "./homepage/Popprofile";
import Cosmoshub from "./homepage/Cosmoshub";
import Objectives from "./homepage/Objectives";
import "../App.css";
import Privacypolicy from "./homepage/Footerpages/Privacypolicy";
import Termsofuse from "./homepage/Footerpages/Termsofuse";
import { researchprojects } from "./utils/ProjectList";
import Projectpage from "./homepage/Projectpage";
import Admin from "./homepage/Adminpages/Admin";
import { menu } from "./utils/List";
import { menu1 } from "./utils/List";
import Mobility from "./homepage/Mobility";

const App = () => {
  const [page, setPage] = useState(0);

  //const location = useLocation();
  const location = useLocation();

  const changePage = (number) => {
    setPage(number);
  };

  console.log(location.pathname);

  return (
    <>
      {location.pathname === "/admin" ? null : <Navbar menulist={menu} />}
      <Routes>
        <Route exact path="/" element={<Homepage />}></Route>
        <Route exact path="/about" element={<Aboutus />}></Route>
        <Route exact path="/blogs" element={<Blogs />}></Route>
        <Route exact path="/subscribe" element={<Subscribe />}></Route>
        <Route exact path="/pop" element={<Pop />}></Route>
        <Route exact path="/cosmos-hub" element={<Cosmoshub />}></Route>
        <Route exact path="/objectives" element={<Objectives />}></Route>
        <Route exact path="/admin" element={<Admin />}></Route>
        <Route exact path="/privacypolicy" element={<Privacypolicy />}></Route>
        <Route exact path="/termsofuse" element={<Termsofuse />}></Route>
        <Route exact path="/mobility" element={<Mobility />}></Route>

        <Route
          exact
          path="/center-of-excellence"
          element={<Centerofexcellence />}
        ></Route>
        <Route
          exact
          path="/research-project"
          element={<Researchproject />}
        ></Route>

        {/* Following routes are for blogs */}

        {blogs.map((blog) => (
          <Route
            exact
            path={`/blogs/${blog.link}`}
            element={<Blogpage link={blog.link} />}
          ></Route>
        ))}

        {blogs.map((blog) => (
          <Route
            exact
            path={`/${blog.link}`}
            element={<Blogpage link={blog.link} />}
          ></Route>
        ))}

        {/* Following routes are for pops*/}

        {poplist.map((pop) => (
          <Route
            exact
            path={`/pop/${pop.link}`}
            element={<Popprofile link={pop.link} />}
          ></Route>
        ))}

        {/* Following routes are for projects*/}
        {researchprojects.map((project) => (
          <Route
            exact
            path={`/research-project/${project.title}`}
            element={<Projectpage link={project.title} />}
          ></Route>
        ))}

        {/* Following routes are for projects via pop*/}
        {researchprojects.map((project) => (
          <Route
            exact
            path={`/pop/${project.title}`}
            element={<Projectpage link={project.title} />}
          ></Route>
        ))}

        {/* Footer Links */}
      </Routes>
    </>
  );
};

export default App;

//Homepage = 0
//About us = 1
//Founder = 2
