import React from "react";

const Contactcard = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };
  return (
    <div className="contactcard">
      {props.img ? (
        <div className="contactcard__imagesection">
          <img className="contactcard__imagesection__img" src={props.img} />
        </div>
      ) : null}

      <div className="contactcard__section">
        <div className="contactcard__title">{props.title}</div>
        {props.subtext ? (
          <div className="contactcard__subtext">{props.subtext}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Contactcard;
