import React from "react";
import SGC from "../../Images/SGC.png";

const SDG = (props) => {
  return (
    <div
      className="SGC"
      style={{ border: "solid 1px", padding: "4rem", borderRadius: "2rem" }}
    >
      <div className="SGC_1">
        <div className="SGC__title">Sustainable Development Goals</div>
        {/* <Blogparagraph
      text="The Sustainable Development Goals (SDGs) are a global framework for
      all to use to achieve a more sustainable future. They recognise that
      ending poverty must go hand-in-hand with strategies that build
      economic growth and address a range of social needs including
      education, health, social protection, and job opportunities, while
      tackling climate change and environmental protection, peace and
      justice."
      align="left"
    /> */}
        <div
          className="aboutpage__pov__paragraph"
          style={{ color: "rgb(1, 51, 51)" }}
        >
          The Sustainable Development Goals (SDGs) are a global framework for
          all to use to achieve a more sustainable future. They recognise that
          ending poverty must go hand-in-hand with strategies that build
          economic growth and address a range of social needs including
          education, health, social protection, and job opportunities, while
          tackling climate change and environmental protection, peace and
          justice.
        </div>
      </div>
      <div className="SGC_2">
        <img className="SGC_2_img" src={SGC} />
      </div>
    </div>
  );
};

export default SDG;
