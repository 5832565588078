import React, { useEffect } from "react";
import Title from "../component/BlogsComponents/Title";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import { whatwedo } from "../utils/AboutInfo";
import Listwithoutheaders from "../component/BlogsComponents/Listwithoutheaders";
import Footer from "./Footer";
import Pathways from "../../Images/pathways.png";
import Collaboration from "../../Images/collab.png";
import Knowledge from "../../Images/knowledge.png";
import Multiplepoints from "../component/BlogsComponents/Multiplepoints";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="aboutpage">
        <div className="aboutpage__missionvisionsection">
          <div className="aboutpage__pagetitle">Our Mission & Vision</div>
          <div className="aboutpage__section">
            <div className="aboutpage__mission">
              <div className="aboutpage__mission__title">Mission</div>
              <div className="aboutpage__mission__paragraph">
                {/* The ArcticTurn Foundation (ATF) fosters{" "} */}
                {/* <p className="aboutpage__mission__paragraph_underline">
                  collaboration, innovation, and sustainable development
                </p>{" "} */}
                The ArcticTurn Foundation (ATF) fosters collaboration,
                innovation, and sustainable development in the thematic sector
                of sustainability, public health and waste water management. We
                connect industries, academics, and researchers globally,
                addressing challenges and empowering startups to make a positive
                impact on society and the environment.
              </div>
            </div>

            <div
              className="aboutpage__mission"
              style={{ backgroundColor: "rgb(2, 27, 25)" }}
            >
              <div className="aboutpage__mission__title">Vision</div>
              <div className="aboutpage__mission__paragraph">
                {/* ATF is to be a leading global facilitator for sustainable
                development. */}
                {/* <p className="aboutpage__mission__paragraph_underline">
                  {" "}
                  We envision a world where industries, academics, and research
                  seamlessly collaborate and work towards a common goal of
                  sustainability.
                </p>{" "} */}
                ATF aims to be a leading global facilitator for sustainable
                development. We envision a world where industries, academics,
                and research seamlessly collaborate and work towards a common
                goal of sustainability, public health and waste water
                management. ATF aims to create a platform where diverse
                stakeholders come together to exchange ideas that address
                pressing global challenges.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="aboutpage__whatwedosection">
          <div className="aboutpage__whatwedosection_title">
            What <br />
            <div className="aboutpage__whatwedosection_title_subtitle">
              Arcticturn Foundation{" "}
            </div>
            Does ?
          </div>
          <div className="aboutpage__whatwedosection__whatwedo">
            <div className="aboutpage__whatwedosection__para">
              <div className="aboutpage__whatwedosection__image">
                <img
                  className="aboutpage__whatwedosection__image_img"
                  src={Collaboration}
                ></img>
              </div>
              <div className="aboutpage__whatwedosection__title">
                Facilitating Collaborations
              </div>{" "}
              ATF COSMOS plays a crucial role in fostering collaborations
              between academics, enterprises, and researchers in the field of
              sustainability. By bringing together individuals and institutions
              with a shared passion for creating a more sustainable future,
              COSMOS serves as a hub for knowledge exchange and cooperative
              efforts.
            </div>
            <div className="aboutpage__whatwedosection__para">
              <div className="aboutpage__whatwedosection__image">
                <img
                  className="aboutpage__whatwedosection__image_img"
                  src={Knowledge}
                ></img>
              </div>
              <div className="aboutpage__whatwedosection__title">
                Knowledge Exchange
              </div>{" "}
              ATF COSMOS recognizes the importance of knowledge exchange in
              advancing sustainability efforts. By providing a platform for
              academics, enterprises, and researchers to share their insights,
              best practices, and research findings, COSMOS facilitates the
              dissemination of valuable information within the sustainability
              community.
            </div>
            <div className="aboutpage__whatwedosection__para">
              <div className="aboutpage__whatwedosection__image">
                <img
                  className="aboutpage__whatwedosection__image_img"
                  src={Pathways}
                ></img>
              </div>
              <div className="aboutpage__whatwedosection__title">
                Create Green Pathways
              </div>{" "}
              As a central hub, COSMOS actively works towards creating green
              pathways that connect individuals and institutions committed to
              sustainability. These pathways serve as channels for
              collaboration, innovation, and action, enabling stakeholders to
              work together towards a common goal.
            </div>
          </div>
        </div> */}
        <Multiplepoints
          text1="What"
          text2="Arcticturn Foundation"
          text3="Does?"
          border="false"
          data={whatwedo}
        />
        <div className="aboutpage__pov">
          <div className="aboutpage__pov__title">Point Of View</div>
          <div className="aboutpage__pov__paragraph">
            At ATF-COSMOS, we recognize the urgent need for interdisciplinary
            collaboration & innovative solutions to address the complex
            challenges facing our planet. By bringing together academics,
            professionals from various universities & dedicated researchers , we
            aim to bridge the gap between theory & practice, fostering a dynamic
            exchange of ideas & initiatives.
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
