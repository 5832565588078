import React, { useEffect } from "react";
import { blogs } from "../utils/BlogsList";
import Blogcard from "../component/Blogcard";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blogs">
        <div className="blogs__header">Blogs</div>
        <div className="blogs__blog">
          {blogs.map((blog) => (
            <Link className="navbar__Mainheader__link" to={blog.link}>
              <Blogcard
                img={blog.img}
                title={blog.title}
                subtext={blog.auther}
                key={blog.value}
              />
            </Link>
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blogs;
