import React from "react";

const Centerofexcellencecard = (props) => {
  return (
    <>
      <div className="centerofexcellencecard">
        {props.img ? (
          <img className="centerofexcellencecard__img" src={props.img} />
        ) : null}

        <div className="centerofexcellencecard__section">
          <div className="centerofexcellencecard__title">{props.title}</div>
          {props.subtext ? (
            <div className="centerofexcellencecard__subtext">
              {props.subtext}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Centerofexcellencecard;
