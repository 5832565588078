import React, { useEffect } from "react";
import Header from "./Header";
import SecondSection from "./SecondSection";
import NewThirdsection from "./NewThirdsection";
import FourthSection from "./FourthSection";
import NEP from "./NEP";
import Footer from "./Footer";
import Founders from "../Founders";
import Aboutus from "../aboutus";
import Purpose from "./Purpose";
import Hero from "./Hero";
import Noticeboard from "./Noticeboard";
import Coreofferings from "./Coreofferings";
import Blogsection from "./Blogsection";
import Contactsection from "./Contactsection";
import Collaboration from "./Collaboration";
import Linkedinwall from "./Linkedinwall";
import SDG from "./SDG";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero />
      {/* <Noticeboard /> */}
      {/* <Collaboration /> */}
      <Linkedinwall />
      <SDG />
      <Coreofferings />
      {/* <Contactsection /> */}

      {/* <Blogsection /> */}

      <Footer />

      {/* <Header />
      <SecondSection />
      <NewThirdsection />
      <NEP />
      <FourthSection />
      <Founders />
      <Purpose />
      <Aboutus /> */}
    </div>
  );
};

export default Homepage;
